<template>
  <div class="branch-shop-options">
    <PageTitle title="區域管理" hideBtn />
    <section>
      <div class="branch-group">
        <div
          v-for="item in userAllowShop"
          :key="item.id"
          class="branch-item"
          @click="selectBranch(item.id)"
        >
          <el-card
            :class="{ 'branch-selected': shop === item.id }"
            shadow="never"
          >
            <div slot="header">
              <img
                class="d-inline-block align-text-bottom mr-2"
                src="@/assets/icon/icon-shop.svg"
              >
              <span>{{ item.name }}</span>
            </div>
            <div class="branch-info">
              <p>{{ item.phone || '-' }}</p>
              <p>{{ item.address || '-' }}</p>
            </div>
          </el-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import configuration from '@/configuration'
import { FindShopPoint } from '@/api/shopPoint'
import { FindShopRebate } from '@/api/rebate'
import { usePermissions } from '@/use/permissions'
import { get } from 'lodash'
import store from '@/store'
export default defineComponent({
  name: 'BranchShopOptions',
  setup () {
    const { checkAction } = usePermissions()

    const getMarketingPermission = async (shopId) => {
      let marketingItem = {
        classTicket: false,
        coupon: false,
        point: false,
        cashback: false,
      }

      let pointCheck = false
      if (checkAction('admin.shopPoint.page')) {
        const [res, err] = await FindShopPoint({ shopId })
        if (res) pointCheck = get(res, 'pointClientId') !== null
        if (err) pointCheck = false
      }
      let cashbackCheck = false
      if (checkAction('admin.shopCashback.page')) {
        const [res, err] = await FindShopRebate({ shopId })
        if (res) cashbackCheck = Object.keys(res).length !== 0
        if (err) cashbackCheck = false
      }

      marketingItem = {
        classTicket: checkAction('admin.classTicket.page'),
        coupon: checkAction('admin.coupon.page') || checkAction('admin.couponExchange.page'),
        point: pointCheck,
        cashback: cashbackCheck,
      }

      store.commit('permission/SET_MARKETINGITEM_PERMISSIONS', { key: 'marketingItem', data: marketingItem })
    }
    return { getMarketingPermission }
  },
  computed: {
    ...mapGetters(['shop', 'shopList', 'userAllowShop', 'appTheme']),
  },
  async created () {
    await this.getMe()
  },
  methods: {
    ...mapActions('user', [
      'getMe',
    ]),
    async selectBranch (shopId) {
      const success = await this.$store.dispatch('auth/changeShop', { shopId, message: this.$message })
      if (!success) return
      await this.getMarketingPermission(shopId)
      await this.$store.dispatch('app/setSidebarPage', 2)
      this.$store.commit('RESET_HOME_PAGE_STORE')
      if (this.appTheme === 'righttime') {
        this.$router.push({ name: 'MembersManage' })
      } else this.$router.push({ name: 'HomePage' })
    },
  },

})
</script>

<style scoped lang="scss">
$primary-1: var(--primary-100);

.branch-shop-options {
  .branch-group {
    @apply flex flex-wrap flex-row;
  }
  .branch-item {
    flex-basis: 33%;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    min-width: 468px;
  }
  .branch-info {
    @apply text-gray-80;
  }
  .el-card {
    box-sizing: border-box;
    border: 2px solid transparent;
    height: 100%;
    padding: 12px 16px;
    cursor: pointer;
    &:hover {
      @apply border-[2px] border-primary-100;
      // border-color: lighten($color: $primary-1, $amount: 10);
    }
    &.branch-selected {
      border-color: var(--primary-100);
    }
    &::v-deep {
      .el-card__header {
        padding: 0px;
        margin-bottom: 24px;
        border-bottom: none;
      }
      .el-card__body {
        padding: 0px;
      }
    }
  }
}
</style>
